<template>
  <div class="income views">
    <van-sticky>
      <van-nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
      />
    </van-sticky>
    <div class="profit-head">
      <div class="head-balance">
        {{ myinfo.orderCount + myinfo.orderCountNo }}
      </div>
      <b>邀请总数</b>
    </div>
    <div class="tag">
      <van-grid :border="false" :column-num="3" class="income-tag">
        <van-grid-item>
          <div class="tag-top">{{ myinfo.userCount }}</div>
          <div class="tag-bottom">注册量</div>
        </van-grid-item>
        <van-grid-item>
          <div class="tag-top">{{ myinfo.markCount }}</div>
          <div class="tag-bottom">访问量</div>
        </van-grid-item>
        <van-grid-item>
          <div class="tag-top">{{ myinfo.orderCount }}</div>
          <div class="tag-bottom">就诊量</div>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="empty-order" v-show="!goodsOrder">
      <div class="empty-order-title">还没有预约~</div>
    </div>
    <div class="goods" v-show="goodsOrder">
      <van-cell-group inset v-for="(item, index) in orderList" :key="index">
        <div class="my-card-one">
          <p>编号：{{ item.time }}</p>
          <hr />
          <p>科室：{{ item.goodsName }}</p>
          <p v-html="consumedFormat(item.consumed)"></p>
          <p class="one-time">{{ timeFormat(item.time) }}</p>
        </div>
      </van-cell-group>
    </div>
  </div>
</template>
<script>
export default {
  name: "Income",
  data() {
    return {
      Authorization: localStorage.getItem(window.lsn),
      title: "我的邀请",
      items: [],
      skip: 0,
      limit: 999,
      scrollOptions: {
        pullDownRefresh: false,
        pullUpLoad: true,
        directionLockThreshold: 0,
      },
      goodsOrder: false,
      orderList: [],
      selectedLabelDefault: "income",
      tabs: [
        {
          label: "返回我的",
          value: "my",
        },
        {
          label: "我的收益",
          value: "income",
        },
        {
          label: "申请提现",
          value: "withdraw",
        },
        {
          label: "达人信息",
          value: "personal",
        },
      ],
      tagDefault: "withdrawable",

      myinfo: {
        income: {
          total: 0,
          withdrawable: 0,
          withdrawn: 0,
          personal: 0,
          team: 0,
        },
      },
    };
  },
  mounted() {
    this.init();
    this.onPullingUp();
  },
  methods: {
    currencyFormat: function (num) {
      num = num + "";
      let start = 0;
      if (num === "0") {
        return "0.00";
      } else {
        start = num.length - 2;
        return num.slice(0, start) + "." + num.slice(start);
      }
    },
    currencyFormat2: function (price, allprice, ouid, puid) {
      let start = 0;
      let nowprice = 0;
      if (ouid === puid) {
        price = price + "";
        start = price.length - 2;
        return price.slice(0, start) + "." + price.slice(start);
      } else {
        nowprice = (allprice - price) * 0.4;
        nowprice = nowprice + "";
        start = nowprice.length - 2;
        return nowprice.slice(0, start) + "." + nowprice.slice(start);
      }
    },
    timeFormat: function (val) {
      let timestamp = null;
      timestamp = new Date(val);
      return (
        timestamp.toLocaleDateString().replace(/\//g, "-") +
        " " +
        timestamp.toTimeString().substr(0, 8)
      );
    },
    init() {
      let $this = null;
      $this = this;
      // 获取个人信息
      this.axios({
        url: this.$store.state.api + "/my/info",
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.Authorization,
        },
      }).then((res) => {
        if (res.data) {
          this.myinfo = res.data;
          if (!$this.myinfo.userinfo) {
            window.sign(window.location.href);
          }
        }
      });
    },
    clickHandler(label) {
      this.$router.push({
        path: label,
      });
    },
    clickTagHandler(label) {
      console.log(label);
    },
    goTeam() {
      this.$router.push({ path: "incometeam" });
    },
    goQr() {
      this.$router.push({
        path: "follow2/?code=" + this.myinfo.invitationCode,
      });
    },
    onPullingUp() {
      let $this = null;
      $this = this;
      // 获取订单
      this.axios({
        url: this.$store.state.api + "/my/incomeorder",
        method: "get",
        params: {
          skip: $this.skip,
          limit: $this.limit,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.Authorization,
        },
      }).then((response) => {
        if (response.data) {
          if ($this.skip === 0 && response.data.length > 0) {
            $this.emptyOrder = false;
            $this.goodsOrder = true;
          }
          /* 获取订单 */
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            $this.orderList.push(element);
          }
          $this.skip = $this.skip + $this.limit;
          $this.loadinglist = false;
          if (response.data.length <= 0) {
            $this.finishedlist = true;
          }
        }
      });
    },
    consumedFormat(val) {
      if (val) {
        return "状态：<span style='color:#0bb000;'>已使用</span>";
      } else {
        return "状态：未使用";
      }
    },
    onClickLeft() {
      history.back();
    },
  },
};
</script>
<style lang="scss">
body {
  background-color: #f0f0f0;
}
.income {
  font-size: 14px;
  background-color: #f2f0f0;
  height: 100vh;
  .van-tabbar-item {
    font-size: 14px;
  }
  .van-nav-bar .van-icon-arrow-left {
    font-size: 24px;
    color: #969799;
  }
  .income-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: #ffffff;
  }
  .profit-head {
    background-color: #f94e47;
    text-align: center;
    padding: 30px 20px 60px 20px;
    border-radius: 0 0 20px 20px;
    position: relative;
    .head-tema {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: #ffffff;
    }
    .head-saoma {
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 24px;
      color: #ffffff;
    }
    .head-level {
      height: 36px;
      overflow: hidden;
      border-radius: 20px;
      margin-top: 10px;
      .van-cell {
        height: 36px;
        line-height: 36px;
        padding: 0 10px;
        font-size: 12px;
        background: #ffdcb1;
        color: #000000;
        .van-cell__title {
          -webkit-box-flex: 3;
          -webkit-flex: 3;
          flex: 3;
          text-align: left;
        }
        .van-cell__value {
          -webkit-box-flex: 1;
          -webkit-flex: 1;
          flex: 1;
          color: #a47948;
        }
        .van-cell__right-icon {
          color: #a47948;
        }
        .van-cell__left-icon {
          font-size: 22px;
          margin-bottom: 3px;
        }
      }
    }
    .head-balance {
      font-size: 32px;
      color: #ffffff;
      font-weight: bold;
      line-height: 46px;
      letter-spacing: 2px;
    }
    b {
      color: #f8c6c6;
      font-size: 14px;
      font-weight: 100;
      letter-spacing: 1px;
    }
  }
  .tag {
    width: 90%;
    height: 66px;
    margin: -33px auto 0;
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px;
    position: relative;
    .tag-bottom {
      margin-top: 2px;
      font-size: 12px;
    }
    .tag-top {
      line-height: 33px;
      font-size: 18px;
      font-weight: bold;
    }
    .income-tag {
      .van-grid-item__content {
        padding: 3px 0;
      }
    }
  }
  .goods {
    padding: 20px 0;
    .van-cell-group {
      margin-bottom: 10px;
      .my-card-one {
        text-align: left;
        padding: 15px 20px;
        background: #ffffff;
        border-radius: 5px;
        font-size: 12px;
        .one-time {
          text-align: right;
          margin: 0;
        }
      }
      .my-card-one hr {
        border: 0.5px #cccccc dashed;
      }
      .my-card-one p span {
        color: rgb(221, 136, 8);
      }
    }
  }
  .empty-order {
    height: 400px;
    width: 100%;
    background: url("/img/noorder1.png") no-repeat center 20px;
    background-size: 68%;
  }
  .empty-order .empty-order-title {
    padding-top: 230px;
    text-align: center;
    color: #888888;
  }
}
</style>